<template>
    <div>
        <ul class="tab_sec">
            <li @click="scheduleTab = 1" :class="{ active: scheduleTab === 1 }">Day</li>
            <li @click="scheduleTab = 2" :class="{ active: scheduleTab === 2 }">Datetime</li>
        </ul>
        <div class="tab_content">
            <div v-if="scheduleTab == 1" class="setting_wpr">
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Day</label>
                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`days_${uniqueId}`] }">
                            <Field autocomplete="off" type="number" min="0" max="100" :name="`days_${uniqueId}`" placeholder="ex: 1" v-model="form.days" rules="numeric" label="days" />
                        </div>
                        <ErrorMessage :name="`days_${uniqueId}`" class="text-danger" />
                    </div>
                </div>
            </div>
            <div v-if="scheduleTab == 2" class="setting_wpr">
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Date</label>
                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`datetime_${uniqueId}`] }">
                            <Field autocomplete="off" type="text" :name="`datetime_${uniqueId}`" v-model="form.datetime" label="datetime">
                                <datepicker name="datetime" v-model="form.datetime" :min-date="moment().format('YYYY-MM-DD HH:mm:ss')" auto-apply placeholder="MM/DD/YYYY HH:MM" format="MM/dd/yyyy HH:mm:ss"></datepicker>
                            </Field>
                        </div>
                        <ErrorMessage :name="`datetime_${uniqueId}`" class="text-danger" />
                    </div>
                    <div class="group_item">
                        <label class="input_label">Timezone</label>
                        <div class="field_wpr">
                            <multiselect
                              v-model="form.timezone"
                              :options="timezones"
                              valueProp="timezone"
                              label="timezone"
                              :searchable="true"
                            >
                                <template v-slot:option="{ option }">
                                    <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'
    import moment from 'moment'

    import { uuid } from 'vue-uuid'
    import { Field, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Playbook Drip Component',

        data () {
            return {
                scheduleTab: 1,
                form: {
                    days: 0,
                    datetime: moment().format('YYYY-MM-DD HH:mm:ss'),
                    timezone: '',
                    drip_method: 1,
                },
                loaded: false,
                moment,
                uniqueId: 'drip_'+uuid.v4(),
            };
        },

        props: {
            modelValue: Object,
            errors: Object,
        },

        emits: ['update:modelValue'],

        components: {
            Multiselect,
            Field,
            ErrorMessage,
        },

        watch: {
            modelValue (modelValue) {
                const vm = this;

                if (!vm.loaded) {
                    vm.form = JSON.parse(JSON.stringify(modelValue));
                    vm.loaded = true;
                    vm.scheduleTab = modelValue.drip_method;
                    vm.form.timezone = vm.form.timezone ? vm.form.timezone : vm.user.timezone;
                }
            },

            scheduleTab (tab) {
                const vm = this;

                vm.form.drip_method = tab;
            },

            form (form) {
                const vm = this;

                if (vm.loaded) {
                    vm.$emit('update:modelValue', form);
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            timezones: state => state.commonModule.timezones,
        }),

        mounted () {
            const vm = this;

            vm.form = JSON.parse(JSON.stringify(vm.modelValue));
            vm.loaded = true;
            vm.scheduleTab = vm.modelValue.drip_method;
            vm.form.timezone = vm.form.timezone ? vm.form.timezone : vm.user.timezone;

            if (vm.timezones.length == 0) {
                vm.getTimezones();
            }
        },

        methods: {
            ...mapActions({
                getTimezones: 'commonModule/getTimezones',
            }),
        },
    }
</script>
